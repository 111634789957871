<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form">
      <a-row>
        <a-form-item label="是否紧急" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-radio-group
            v-decorator="['ifUrgent', {initialValue: false,rules: [{ required: true, message: '请选择是否紧急' }] }]">
            <a-radio value=true>
              紧急
            </a-radio>
            <a-radio value=false>
              不紧急
            </a-radio>
          </a-radio-group>
        </a-form-item>
<!--        <a-form-item label="清运日期"-->
<!--                     :label-col="labelCol"-->
<!--                     :wrapper-col="wrapperCol">-->
<!--          <a-date-picker style="width:20%" :showTime="{ format: 'YYYY-MM-DD' }"-->
<!--                         format="YYYY-MM-DD"-->
<!--                         v-decorator="['latestDate',{rules: [{ required: true, message: '最晚清运时间不能为空' }]}]"-->
<!--                         placeholder="请选择"></a-date-picker>-->
<!--          <a-radio-group default-value="tomorrow" button-style="ghost" @change="dateChange" style="margin-left: 34px">-->
<!--            <a-radio-button value="toDay">-->
<!--              今天-->
<!--            </a-radio-button>-->
<!--            <a-radio-button value="tomorrow">-->
<!--              明天-->
<!--            </a-radio-button>-->
<!--            <a-radio-button value="afterTomorrow">-->
<!--              后天-->
<!--            </a-radio-button>-->
<!--          </a-radio-group>-->
<!--        </a-form-item>-->
        <a-form-item label="清运司机"
                     :label-col="labelCol"
                     :wrapper-col="wrapperCol">
          <a-tree-select
            v-decorator="['driverId',
                {
                  validateTrigger: ['change'],
                  rules: [{
                    message: '请选择司机',
                  }]
                }
              ]"
            @select="driverSelect"
            treeDefaultExpandAll
            treeNodeFilterProp="title"
            style="width: 100%"
            :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
            :treeData="orgUserTreeData"
            placeholder="请选择司机"
          />
        </a-form-item>
      </a-row>
    </a-form>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { dispatchFormVO } from './common/common'
  import moment from 'moment'

  export default {
    name: 'DispatchAssignForm',
    data() {
      return {
        form: this.$form.createForm(this),
        orgUserTreeData: [],
        labelCol: { span: 5 },
        wrapperCol: { span: 17 },
        formItem: dispatchFormVO(),
        spinShow: false,
        tempDriverId: null
      }
    },
    created() {
      this.loadUserList()
    },
    methods: {
      loadUserList() {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: '',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.orgUserTreeData = data.body
          }
        })
      },
      loadData(id) {
        this.formItem = dispatchFormVO()
        this.form.resetFields()
        this.$http(this, {
          url: SERVICE_URLS.csgz.reportRecord.view,
          loading: 'spinShow',
          params: {
            id: id
          },
          noTips: true,
          success: (data) => {
            this.setFields(data.body)
            setTimeout(() => {
              this.$nextTick(() => {
                this.tempDriverId = data.body.reportRecord.driverId
                this.formItem.id = id
                const driverId = this.getSelectKey(this.orgUserTreeData, this.tempDriverId)
                if (driverId) {
                  this.form.setFieldsValue({
                    'driverId': driverId
                  })
                }
              })
            }, 200)
          }
        })
      },
      setFields(values) {
        Object.assign(this.formItem, values)
        this.form.setFieldsValue({
          ifUrgent: this.formItem.reportRecord.ifUrgent + '',
          latestDate: this.formItem.reportRecord.latestDateTime
        })
      },
      driverSelect(value) {
        this.tempDriverId = value.split('_')[2]
      },
      setVOFields(values) {
        Object.assign(this.formItem, values)
        if (values.driverId) {
          this.formItem.driverId = values.driverId.split('_')[2]
        }
      },
      submitEditDispatch() {
        console.info('<<<<<')
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.csgz.reportRecord.updateDispatch,
              data: this.formItem,
              loading: 'tableLoading',
              noTips: false,
              success: () => {
                this.formItem = dispatchFormVO()
                this.$emit('dispatchSuccess')
              }
            })
          }
        })
      },
      dateChange(e) {
        if (e.target.value === 'toDay') {
          this.form.setFieldsValue({
            latestDate: moment(new Date(), 'YYYY-MM-DD')
          })
        } else if (e.target.value === 'tomorrow') {
          this.form.setFieldsValue({
            latestDate: moment(new Date(), 'YYYY-MM-DD').add(1, 'days')
          })
        } else {
          this.form.setFieldsValue({
            latestDate: moment(new Date(), 'YYYY-MM-DD').add(2, 'days')
          })
        }
      },
      getSelectKey(list, id) {
        var key
        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          const tempKey = item.key
          if (tempKey.substring('USER_') !== -1 && Number(tempKey.split('_')[2]) === id) {
            key = tempKey
            return key
          } else if (item.children.length > 0) {
            key = this.getSelectKey(item.children, id)
          }
          if (key) return key
        }
      }
    }
  }
</script>

<style scoped>

</style>